<template>
  <div class="business-detail" v-loading="loading">
    <div class="detail">
      <div class="title">{{ opportunityData.name }}</div>
      <ul class="info">
        <li>商机ID：{{ opportunityData.id }}</li>
        <li>上报时间： {{ opportunityData.time }}</li>
        <li>最后跟进时间：{{ opportunityData.lastTime }}</li>
        <li>当前状态：<span :class="opportunityData.state === 2 ? 'blue' : 'green'">{{ opportunityData.state === 2 ? '已转立项' : '跟进中' }}</span></li>
      </ul>
    </div>
    <div class="message">
      <div class="title">客户信息</div>
      <ul>
        <li v-for="(item, index) in customerData" :key="index">
          <div class="li-title">{{ item.title }}</div>
          <div class="li-content">{{ item.content }}</div>
        </li>
      </ul>
    </div>
    <div class="message">
      <div class="title">商机信息</div>
      <ul>
        <li v-for="(item, index) in businessData" :key="index" :class="[item.title === '解决方案' || item.title === '客户需求' || item.type || item.product ? 'line' : '', index === 5 || index === 6 ? 'merge' : '']">
          <div class="li-title">{{ item.title }}</div>
          <div v-show="item.type">{{item.type}}</div>
          <div v-show="'content' in item" class="li-content"><span v-if="item.content && item.content != 0.00">{{ item.content }}</span><span v-else-if="item.content == 0.00">-</span><span v-else>-</span><span v-show="index===2 && item.content != '0.00'">（万元）</span></div>
          <div v-show="item.text" class="li-text"><span v-for="(item1,index) in item.text" :key="index"><span>{{ item1 || '-' }}</span></span></div>
          <div v-show="item.product" class="li-product">
            <div class="se_li" v-for="(item2,index) in item.product" :key="index">
              <div class="st_left">
                <img :src="item2.product_img[0]" alt="">
              </div>
              <div class="st_right">
                <div class="str1">{{item2.name}}</div>
                <div class="str2"><span style="color: #FD5451">￥{{item2.price }}</span>/{{item2.charge_method}}</div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="attachment">
      <div class="title">附件</div>
      <div class="content" v-if="attachments.path">
        <div style="display: flex">
          <div class="file">
            <img src=@/assets/business/annex_icon@2x.png>
            <p>{{attachments.name}}</p>
          </div>
          <div class="operate">预览</div>
          <div class="operate" @click="operateDown">下载</div>
        </div>
      </div>
      <div class="content" v-else>
        暂无附件
      </div>
    </div>
    <div class="record" v-show="recordList.length>0">
      <div class="record_box">
        <div class="title">跟进记录</div>
        <el-timeline>
          <el-timeline-item v-for="(item, index) in recordList" :key="index">
            <div class="list"><span class="time">{{ item.create_time }}</span>
              <div class="content">{{ item.content }}</div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
      <a href="JavaScript:;" @click="add_record" class="add_record">添加跟进记录</a>
    </div>
    <div class="detail-btn">
      <span style="cursor: pointer" v-if="showOperate(data)" class="sure" @click="changeApproval">转为立项</span>
      <span style="cursor: pointer" v-if="this.status == 2" class="sure" @click="backBtn">确认</span>
      <span style="cursor: pointer" class="return" @click="backBtn">返回</span>
    </div>
    <!--弹窗-->
    <el-dialog
        class="follow_closely"
        title="添加跟进记录"
        :visible.sync="dialogVisible1"
        width="30%"
        :before-close="handleClose">
      <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="follow_text"
          maxlength="100"
          show-word-limit
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible1 = false">取 消</el-button>
    <el-button type="primary" @click="follow_up_confirmation">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {area_child_list, business_info} from "../../request/businessOpportunityPool";

export default {
  name: 'BusinessDetail',
  data() {
    return {
      loading: true,
      follow_text:'',
      dialogVisible1:false,
      attachments:{
        name:'',
        path:''
      },
      data: {},
      status:1,
      businessManagerOptions:null,
      businessSource:null,
      business_manager_id:null,
      source_id:null,
      main_business_type:null,
      multiple_type:null,
      allBusiness:{
        businessBasicsType:{},
        businessInnovateType:{},
        businessInternationalType:{}
      },
      opportunityData: { name: '这里是商机名称', id: 'SJ2403170001', time: '2024/03/01 12:23:34', lastTime: '2024/03/0112:23:34', state: 1 }, //商机信息
      customerData: [
        { title: '所在地区', content: '云南省-普洱市-思茅区'}, {title: '所属网格', content: '昭阳区商务楼宇商企网格' }, { title: '企业名称', content: '7天连锁酒店（高新区昊万店）' },
        { title: '联系人', content: '张三丰' }, { title: '联系电话', content: '18822226666' }, { title: '商企经理', content: '张三丰（zhangsanfeng）' },
      ], // 客户信息
      businessData: [
        { title: '商机来源', content: '客户拜访' }, { title: '预计签约时间', content: '2024-02-09' }, { title: '预计合同金额', content: '' },
        {title:'客户需求',content: ''},
        {title: '主要业务类型',type:'创新业务',content: ''},
        {title: '',type: '基础业务',text:[]},
        {title: '次要业务类型',type: '创新业务',text:[]},
        {title: '',type: '国际业务',text:[]},
        {title: '主要产品',product:[]},
        { title: '解决方案', content: '' }
      ], // 商机信息
      mainTypeList: [
        {
          title: '创新业务', radio: '2',
          radioList: [ {label: '1', value: '5G+X'}, {label: '2', value: '云计算'}, {label: '3', value: '大数据'}, ]
        }
      ], // 主要业务类型
      secondaryTypeList: [
        {
          title: '创新业务', checkbox: ['2', '3'],
          checkboxList: [ {label: '1', value: '5G+X'}, {label: '2', value: '云计算'}, {label: '3', value: '大数据'}, ]
        },
        {
          title: '创新业务', checkbox: ['1', '3'],
          checkboxList: [ {label: '1', value: '5G+X'}, {label: '2', value: '云计算'}, {label: '3', value: '大数据'}, ]
        },
        {
          title: '创新业务', checkbox: ['2'],
          checkboxList: [ {label: '1', value: '5G+X'}, {label: '2', value: '云计算'}, {label: '3', value: '大数据'}, ]
        },
      ], // 次要业务类型
      demandContent: '对于问题的实际分析，决定了解决方案的针对性和有效性，如果解决方案本身有欠缺，那么可能在执行中导致更多的问题，达不到预期的效果。解决方案的产生过程，大致可分为：确定问题对象和影响范围→ 分析问题→ 提出解决问题的办法和建议→ 成本规划和可行性分析→ 执行→ 后期跟进和交互修正→ 总结', // 客户需求简介
      recordList: [], // 跟进记录
    }
  },
  methods: {
    follow_up_confirmation(){
      let data = {
        business_id:this.$route.query.id,
        content:this.follow_text
      }
      this.$api.business_add_follow(data).then(res=>{
        if (res.status == 200){
          this.$api.business_info({id:this.$route.query.id}).then(res=>{ //成功之后再次请求数据
            this.recordList = res.data.follows
          })
          this.$message({
            message: '添加成功！',
            type: 'success'
          });
          this.follow_text = '';
          this.dialogVisible1 = false;
        }
      })
    },
    handleClose(done){
      done()
    },
    add_record(){ //添加记录
      this.dialogVisible1 = true
    },
    showOperate(row) { //显示操作按钮
      let result = false;
      let userInfo = this.$store.state.userInfo;
      if (row.status === 1 && userInfo.role_id === 1 && userInfo.admin_id === row.business_manager_id) {
        result = true;
      } else if (row.status === 1 && (userInfo.role_id === 2 || userInfo.admin_id === 1)) {
        result = true;
      }
      return result;
    },
    operateDown(){
      this.fileDownload(this.attachments.path)
    },
    backBtn(){
      this.$router.push('/businessLeads')
    },
    getAllData(){
      this.$api.business_info({id:this.$route.query.id}).then(res=>{
        console.log(res,4587)
        this.data = res.data
        this.status = res.data.status
        this.opportunityData.name = res.data.name
        this.opportunityData.id = res.data.sj_id
        this.opportunityData.time = res.data.create_time
        let timeIndex = res.data.follows.length - 1
        if(timeIndex>0){
          this.opportunityData.lastTime = res.data.follows[0].update_time
        }else {
          this.opportunityData.lastTime = '-'
        }
        this.opportunityData.state = res.data.status
        this.customerData[0].content = res.data.area_name
        this.customerData[1].content = this.$route.query.grid
        this.customerData[2].content = this.$route.query.company
        this.customerData[3].content = res.data.link_people
        this.customerData[4].content = res.data.link_phone
        this.customerData[5].content = res.data.business_manager_name
        this.business_manager_id = res.data.business_manager_id
        this.source_id = res.data.source_id
        this.businessData[1].content = res.data.predict_time
        this.businessData[2].content = res.data.predict_contract_money
        this.businessData[9].content = res.data.product_solution
        this.main_business_type = res.data.main_business_type
        this.multiple_type = res.data.minor_business_type
        this.businessData[3].content = res.data.content
        if (this.source_id == 79){
          if (res.data.productInfo != null){
            this.businessData[8].product = res.data.productInfo.map(item => item.product);
          }
        }else {
          this.businessData[8].product = res.data.product
        }
        this.demandContent = res.data.content
        if (res.data.attachments){
          let attachment = JSON.parse(res.data.attachments);
          this.attachments = attachment
        }
        this.recordList = res.data.follows
        console.log(res,777)
        this.getBusinesser();
      })
    },
    getBusinesser(){
      this.$api.business_type_info().then(res=>{
        this.businessManagerOptions = res.data.user
        this.businessSource = res.data.sourceInfo
        let source = null
        const selectedSource = this.businessSource.find(scurce => scurce.id === this.source_id);
        if (selectedSource){
          source = selectedSource.dict_name
        }
        // this.customerData[5].content = account //获取商企经理的值
        this.businessData[0].content = source //获取商机来源

        this.allBusiness.businessBasicsType = res.data.businessBasicsType
        this.allBusiness.businessInnovateType = res.data.businessInnovateType
        this.allBusiness.businessInternationalType = res.data.businessInternationalType

        let result = this.findMatchingIds(this.allBusiness,this.main_business_type) //修改主要业务的值
        if (result){
          this.businessData[4].type = result.dict_name
          this.businessData[4].content = result.name
          // this.mainTypeList[0].radio = this.main_business_type
          // this.mainTypeList[0].radioList = result.list
        }

         /*获取次要业务*/
        this.secondaryTypeList = this.allBusiness
        let multiple_type = JSON.parse(this.multiple_type);
        console.log(multiple_type,148)
        for (let key in this.secondaryTypeList) {
          if (this.secondaryTypeList.hasOwnProperty(key)) {
            let obj = this.secondaryTypeList[key];
            let dict_code = obj.dict_code;
            // 遍历 multiple_type 对象
            for (let mtKey in multiple_type) {
              if (multiple_type.hasOwnProperty(mtKey) && mtKey === dict_code) {
                // 给 secondaryTypeList 对象添加 radio 属性
                let valueArray = multiple_type[mtKey];
                // 将字符串数组转换为数字数组
                let numericValues = valueArray.map(parseFloat);
                obj.checkbox = numericValues;
                break; // 已找到匹配，结束循环
              }
            }
          }
        }
        this.extractDictNames(this.secondaryTypeList);
        this.businessData[5].type = this.secondaryTypeList.businessInnovateType.dict_name
        this.businessData[5].text = this.secondaryTypeList.businessInnovateType.matchedDictNames

        this.businessData[6].type = this.secondaryTypeList.businessBasicsType.dict_name
        this.businessData[6].text = this.secondaryTypeList.businessBasicsType.matchedDictNames

        this.businessData[7].type = this.secondaryTypeList.businessInternationalType.dict_name
        this.businessData[7].text = this.secondaryTypeList.businessInternationalType.matchedDictNames


        this.loading = false //loading结束
      })
    },
    /*findMatchingIds(allBusiness, main_business_type) { //获取主要业务的方法
      let matchingIds = null;
      for (let key in allBusiness) {
        let obj = allBusiness[key];
        if (obj.hasOwnProperty('list')) {
          let list = obj.list;
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.hasOwnProperty('id') && item.id === main_business_type) {
              matchingIds = obj
            }
          }
        }
      }
      return matchingIds;
    },*/
    findMatchingIds(allBusiness, main_business_type) { //获取主要业务的方法
      let matchingIds = null;
      for (let key in allBusiness) {
        let obj = allBusiness[key];
        if (obj.hasOwnProperty('list')) {
          let list = obj.list;
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.hasOwnProperty('id') && item.id === main_business_type) {
              matchingIds = obj
              matchingIds.name = item.dict_name;
            }
          }
        }
      }
      return matchingIds;
    },
    extractDictNames(obj) {
      // 遍历对象的每个属性
      for (let prop in obj) {
        // 在当前属性中添加一个新数组用于存放匹配的 dict_name 属性值
        obj[prop].matchedDictNames = [];

        // 获取当前属性的列表和复选框数组
        let list = obj[prop].list;
        let checkbox = obj[prop].checkbox;

        // 添加有效性检查
        if (checkbox && Array.isArray(list)) {
          // 遍历列表中的每个对象
          list.forEach(item => {
            // 添加有效性检查
            if (item && item.id && checkbox.includes(item.id)) {
              // 将对应的 dict_name 属性值添加到新数组中
              obj[prop].matchedDictNames.push(item.dict_name);
            }
          });
        }
      }
    },
    changeApproval(){ //转为立项按钮
      this.$confirm('确定转为立项吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        let id = this.$route.query.id
        let data = {
          id :[id]
        }
        this.$api.business_update_status(data).then(res=>{
          if (res.status == 200){
            this.$message({
              type: 'success',
              message: '转为立项成功!'
            });
            this.$router.push('/businessLeads')
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消转为立项'
        });
      });
    }
  },
  mounted() {
    this.getAllData();
  }
}
</script>

<style lang="scss" scoped>
.business-detail {margin: 18px;padding: 30px;background: #FFFFFF;
  .detail {margin-bottom: 30px;padding: 15px 25px;background: #F6F7F9;border: 1px solid #EDF1F4;
    .title {margin-bottom: 14px;font-size: 18px;color: #242D33;line-height: 28px;}
    ul {display: flex;flex-wrap: wrap;color: #6C7880;}
    li {margin-right: 50px;margin-bottom: 8px;}
    .blue {color: #3984F5;}
    .green {color: #04CD83;}
  }
  .message {margin-bottom: 30px;
    .title {position: relative;margin-bottom: 20px;font-size: 18px;line-height: 20px;}
    .title::before {position: absolute;left: -30px;top: 0;content: '';width: 4px;height: 20px;background-color: #3984F5;}
    ul {display: flex;flex-wrap: wrap;border-top: 1px solid #DBE1E5;border-left: 1px solid #DBE1E5;}
    li {display: flex;width: 33.3333%;line-height: 22px;
      >div {display: flex;align-items: center;padding: 9px 20px 8px;border-right: 1px solid #DBE1E5;border-bottom: 1px solid #DBE1E5;box-sizing: border-box;align-items: center;}
    }
    li.merge{
      .li-title{
        border-bottom:none;
      }
    }
    .line {width: 100%;}
    .li-title {width: 138px;color: #444F57;background: #F6F7F9;flex-shrink: 0}
    .li-content {flex: 1;}
    .li-text {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      span {
        padding-right: 15px;
        border-right: 1px solid #E1E8EC;
        margin-right: 15px;

        &:last-child {
          border-right: none;
        }
      }
    }
    .li-product{
      flex: 1;
      flex-wrap: wrap; /* 允许换行 */
      padding-bottom: 30px;
      .se_li{
        margin-top: 20px;
        margin-right: 18px;
        display: flex;
        width: 340px;
        height: 108px;
        background: #FAFCFE;
        padding: 14px;
        box-sizing: border-box;
        &:nth-child(4n){
          margin-right: 0;
        }
        .st_left{
          flex-shrink: 0;
          margin-right: 14px;
          img{
            width: 80px;
            height: 80px;
            background: #CEDAE0;
            border-radius: 4px;
          }
        }
        .st_right{
          .str1{
            height: 46px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #21333F;
            line-height: 23px;
            width: 210px; /* 设置固定宽度 */
            white-space: nowrap; /* 防止文本换行 */
            overflow: hidden; /* 隐藏超出部分 */
            text-overflow: ellipsis; /* 显示省略号 */
          }
          .str2{
            margin-top: 12px;
            color: #A7B2B8;
            font-size: 14px;
          }
        }
      }
    }
  }
  .main-title {margin-bottom: 14px;}
  .business-type {
    .item {display: flex;align-items: center;margin-bottom: 18px;}
    .item-title {margin-right: 30px;color: #6C7880;}
  }
  .demand {margin-bottom: 20px;
    .content {padding: 20px;background: #F6F7F9;line-height: 22px;color: #242D33;}
  }
  .attachment {display: flex;margin-bottom: 30px;line-height: 40px;
    .title {width: 62px;}
    .content {display: flex;}
    .file {display: flex;align-items: center;padding: 0 18px;width: 440px;height: 40px;border-radius: 4px;background: #F6FBFE;
      img {margin-right: 12px;width: 13px;height: 14px;}
      p {flex: 1;overflow: hidden;text-overflow: ellipsis;white-space: nowrap}
    }
    .operate {margin:0 12px;color: #3984F5;cursor: pointer;}
  }
  .record_box {display: flex;
    .title {width: 62px;}
    .list {display: flex;font-size: 12px;color: #6C7880;}
    .time {margin-right: 58px;}
    .content {flex: 1;}
  }
  .record{
    margin-bottom: 30px;
    .add_record{
      color: #3984F5;
      margin-left: 62px;
    }
  }
  .detail-btn {display: flex;margin-left: 62px;
    span {display: block;margin-right: 10px;width: 120px;height: 40px;line-height: 40px;text-align: center;border-radius: 4px;}
    .sure {background: #3984F5;color: #FFFFFF;}
    .return {background: #EEF1F3;color: #6C7880;}
  }
}
::v-deep .follow_closely{
  .el-dialog{
    margin-top: 30vh !important;
    width: 500px !important;
    .el-textarea__inner{
      height: 136px;
    }
    .el-dialog__footer{
      text-align: center;
    }
  }
}
::v-deep .el-radio-group {
  .el-radio__label {font-size: 12px;}
  .el-radio__input.is-disabled .el-radio__inner {background-color: #F6F7F9;border-color: #CEDAE0;}
  .el-radio__input.is-disabled+span.el-radio__label {color: #A7B2B8;}
  .el-radio__input.is-disabled.is-checked .el-radio__inner {border-color: #3984F5;background: #3984F5;}
  .el-radio__input.is-disabled.is-checked .el-radio__inner::after {background: #F5F7FA;}
  .el-radio__input.is-disabled.is-checked+span.el-radio__label {color: #21333F;}
}
::v-deep .el-checkbox-group {
  .el-checkbox__label {font-size: 12px;}
  .el-checkbox__input.is-disabled .el-checkbox__inner {background-color: #F6F7F9;border-color: #CEDAE0;}
  .el-checkbox__input.is-disabled+span.el-checkbox__label {color: #A7B2B8;}
  .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {border-color: #3984F5;background: #3984F5;}
  .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {border-color: #F5F7FA;}
  .el-checkbox__input.is-disabled.is-checked+span.el-checkbox__label {color: #21333F;}
}
::v-deep .el-timeline {flex: 1;margin-top: 2px;
  .el-timeline-item {padding-bottom: 30px;}
  .el-timeline-item__node {width: 13px;height: 13px;border: 2px solid #3984F5;background: #EEF3FB;box-sizing: border-box;}
  .el-timeline-item__tail {left: 5px;border-left: 1px solid #CEDAE0;}
}
</style>


